import './profile.css'
import { useEffect, useState } from 'react'
import { useAuthValue } from './AuthContext'
import { signOut } from 'firebase/auth' 
import { db, auth } from './firebase'
import { Link, useNavigate } from 'react-router-dom'
import { collection, query, where, doc, getDocs, getDoc, deleteDoc, updateDoc, orderBy } from "firebase/firestore";

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

function Items() {

  const {currentUser} = useAuthValue();
  const [data, setData] = useState('');

  const navigate = useNavigate()
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    
    const getData = async () => {

      console.log("asd");
      
      let items = [];
      const q = query(collection(db, "items"), where("userid", "==", currentUser.uid), orderBy("createdAt", "desc"));
      const querySnapshot =  await getDocs(q);

      querySnapshot.forEach((doc) => {
        items.push({
          id: doc.id,
          shared: doc.data().shared,
          createdAt: doc.data().createdAt,
          title: doc.data().title
        })
      });

      setData(items);

    };

    getData();

  }, [currentUser.uid]);

  const deleteItem = async (e) => {
    e.preventDefault();
    let id = e.target.getAttribute("data-id")
    await deleteDoc(doc(db, "items", id));
    
    const index = data.findIndex(function(item){
      return item.id === id;
    });
    if (index !== -1) {
      data.splice(index, 1);
      forceUpdate();
    }
    
  }

  const shareItem = async (e) => {
    e.preventDefault();
    let id = e.target.getAttribute("data-id")
    let share = !(e.target.getAttribute("data-share") === "true")

    const docSnap = await getDoc(doc(db, "profiles", currentUser.uid));
            
    if (!docSnap.exists()) {
        return navigate('/profile/');
    }
    
    await updateDoc(doc(db, "items", id), {
      shared: share
    });

    const index = data.findIndex(function(item){
      return item.id === id;
    });
    if (index !== -1) {
      data[index].shared = share;
      if (share) {
        navigate('/itys/' + id);
      } else {
        forceUpdate();
      }
    }
    
  }

  if (data) {

    let items = data.map((item) => {
      let shareText = (item.shared) ? "Unshare" : "Share";
      let createdAt = item.createdAt.toDate().toLocaleDateString();
      return (
        <div key={item.id}>
          {createdAt}:&nbsp; 
          {item.title}&nbsp; 
          (<span className="link" data-id={item.id} onClick={deleteItem}>Delete</span> | <span className="link" data-id={item.id} data-share={item.shared} onClick={shareItem}>{shareText}</span>)
        </div>
      );
    });

    return (

        <div>
          <div className="user">{currentUser?.email} (<span className="link" onClick={() => signOut(auth)}>Sign Out</span>)</div>
          <div className="items">{items}</div>
          <div><Link to='/new'>New ITYS</Link></div>
          <div><Link to='/profile'>Profile</Link></div>

        </div>
        
    )
  } else {
    return null;
  }
}

export default Items
