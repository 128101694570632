import { useEffect, useState } from 'react'
import { useAuthValue } from './AuthContext'
import { signOut } from 'firebase/auth' 
import { db, auth } from './firebase'
import { createRoutesFromChildren, useParams } from 'react-router-dom'
import { collection, query, where, doc, getDocs, getDoc, limit , documentId} from "firebase/firestore";



function Item() {

    const { id } = useParams();
    const [data, setData] = useState('');

    useEffect(() => {
        
        const getData = async () => {

          try {
            
            const q = query(collection(db, "items"), where("shared", "==", true), where(documentId(), "==", id), limit(1));
            const querySnapshot =  await getDocs(q);

            if (!querySnapshot.empty) {

              let items = [];

              querySnapshot.forEach((doc) => {
                items.push({
                  id: doc.id,
                  shared: doc.data().shared,
                  title: doc.data().title,
                  userid: doc.data().userid,
                  createdAt: doc.data().createdAt
                })
              });

              const docSnap = await getDoc(doc(db, "profiles", items[0].userid));
            
              if (docSnap.exists()) {
                items[0].name = docSnap.data().name; 
              }
        
              setData(items);

            }

         
            
            
          } catch(e) {
            console.log(e);
          }
        }
      
          getData();
      
        }, [id]);
   











    if (data) {

      let createdAt = data[0].createdAt.toDate().toLocaleDateString();

        return (

            <div class="item">
            On {createdAt} {data[0].name} said:<br/><strong>{data[0].title}</strong>
            </div>
            
        )
        } else {

        return (
            <div>
            Not found
            </div>

        )

        }
  } 


export default Item
