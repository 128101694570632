import {useState} from 'react'
import './forms.css'
import {db} from './firebase'
import {useNavigate, Link} from 'react-router-dom'
import { useAuthValue } from './AuthContext'
import { collection, addDoc, Timestamp  } from "firebase/firestore"; 

function New() {

    const [title, setTitle] = useState('')
    const [error, setError] = useState('')
    const navigate = useNavigate()

    const {currentUser} = useAuthValue();
  
    const validateTitle = () => {
      let isValid = true
      if (title === ''){
          isValid = false
          setError('Title cannot be empty...')
        }
        return isValid
      }
      
  
    const newItem = async e => {
      e.preventDefault()
      setError('')
      if(validateTitle()) {

        try {
        const docRef = await addDoc(collection(db, "items"), {
            userid: currentUser.uid,
            title: title,
            shared: false,
            createdAt: Timestamp.fromDate(new Date())
          });
          console.log("Document written with ID: ", docRef.id);
          navigate('/')
        } catch (e) {
            console.log("Error:" + e.message);
            setError('Oops. Something went wrong...')
        }
      }
      setTitle('');
    }
  
    return (
      <div className='center'>
        <div className='auth'>
          <h1>New</h1>
          {error && <div className='auth__error'>{error}</div>}
          <form onSubmit={newItem} name='new_form'>
            <input 
              type='text' 
              value={title}
              placeholder="Enter title"
              required
              onChange={e => setTitle(e.target.value)}/>
  
            <button type='submit'>New</button>
          </form>
          
        </div>
      </div>
    )
  }
  
  export default New