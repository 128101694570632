import { initializeApp } from 'firebase/app'
import {getAuth} from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDgpq6gxnFHZ8vQwSMDTk8xlhKIEhgAvx4",
  authDomain: "it0ldy0us0.firebaseapp.com",
  databaseURL: "https://it0ldy0us0-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "it0ldy0us0",
  storageBucket: "it0ldy0us0.appspot.com",
  messagingSenderId: "899713161894",
  appId: "1:899713161894:web:257ce1b666747d1aae3267",
  measurementId: "G-K9KYMEXDZE"
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app);
const analytics = getAnalytics(app);



export {db, auth, analytics}
