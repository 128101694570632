import {useState, useEffect} from 'react'
import './forms.css'
import {db} from './firebase'
import { useAuthValue } from './AuthContext'
import { setDoc, getDoc, doc } from "firebase/firestore"; 

function Profile() {

    const [name, setName] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const {currentUser} = useAuthValue();
  
    const validateName = () => {
        let isValid = true
        if (name === ''){
            isValid = false
            setError('Name cannot be empty...')
        }
        return isValid
    }

    useEffect(() => {
    
        const getProfile = async () => {
          
            const docRef = doc(db, "profiles", currentUser.uid);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
              setName(docSnap.data().name)
            } else {
              console.log("No such document!");
            }
    
        };
    
        getProfile();
    
      }, [currentUser.uid]);
      
  
    const updateProfile = async e => {
      e.preventDefault();
      setError('');
      setSuccess('');
      if(validateName()) {
        try {

            await setDoc(doc(db, "profiles", currentUser.uid), {
              name: name
            }).then(() => {
                setSuccess('Success!')
            });

        } catch (e) {
            console.log("Error:" + e.message);
            setError('Oops. Something went wrong...')
        }
      }
    }
  
    return (
      <div className='center'>
        <div className='auth'>
          <h1>Profile</h1>
          {error && <div className='auth__error'>{error}</div>}
          {success && <div className='auth__success'>{success}</div>}
          <form onSubmit={updateProfile} name='new_form'>
            <input 
              type='text' 
              value={name}
              placeholder="Enter name"
              required
              onChange={e => setName(e.target.value)}/>
            
            <button type='submit'>Update</button>
          </form>
          
        </div>
      </div>
    )
  }
  
  export default Profile